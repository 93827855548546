import React, { useState, useEffect } from "react";
import axios from "axios";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import jwt_decode from "jwt-decode";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Dvr from "@material-ui/icons/Dvr";
import ReactFilestack from "filestack-react";
import Success from "components/Typography/Success.js";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import FormLabel from "@material-ui/core/FormLabel";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import Danger from "components/Typography/Danger";

import { colouredLineChart } from "variables/charts.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Maquina(props) {
  const classes = useStyles();
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const options = {
    maxFiles: 1,
    fromSources: ["local_file_system", "audio"],
  };
  const idEquipo = props.match.params._id;
  const [equipo, setEquipo] = React.useState();
  const [informes, setInformes] = React.useState([]);
  const [informesExtra, setInformesExtra] = React.useState([]);
  const [notas, setNotas] = React.useState([]);
  const [notaModal, setNotaModal] = React.useState(false);
  const [filesModal, setFilesModal] = React.useState(false);
  const [variablesModal, setVariablesModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [textNote, setTextNote] = useState("");
  const [nameDescription, setNameDescription] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [urlFile, setUrlFile] = useState();
  const [urlFileError, setUrlFileError] = useState();
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [nameGraphOne, setNameGraphOne] = useState("");
  const [nameGraphTwo, setNameGraphTwo] = useState("");
  const [tc, setTC] = React.useState(false);
  const [vartoEdit, setVartoEdit] = useState({});
  const [vartoEditModal, setVartoEditModal] = useState(false);
  const [variblesDeMaquina, setVariablesDeMaquina] = useState();
  const [dataGraficosOne, setDataGraficoOne] = useState({
    labels: [],
    series: [],
  });
  const [dataGraficosTwo, setDataGraficoTwo] = useState({
    labels: [],
    series: [],
  });

  // CARGA DEL EQUIPO
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondas/maquina/${idEquipo}`
      `https://epstool.com/api/rondas/maquina/${idEquipo}`
    );
    setEquipo(result.data);
    setInformes(result.data.informes);
    fetchGraph(result.data.informes);
    setNotas(result.data.notas);
    setFiles(result.data.files);
    if (result.data.variablesPersonalizadas === true) {
      setVariablesDeMaquina(result.data.variables);
    } else {
      setVariablesDeMaquina(result.data.tipo.variables);
    }
  };

  const fetchGraph = async (informes) => {
    let flag = 0;
    let counter = 0;
    let valoresOne = [];
    let labelOne = [];
    let labelTwo = [];
    let valoresTwo = [];
    let labelOnes = "";
    let labelTwos = "";
    informes.forEach((informe, index) => {
      flag = 0;
      counter = 0;
      informe.informacion.forEach((info, index) => {
        if (info.tipo === 2) {
          if (flag <= 2) {
            switch (counter) {
              case 0:
                valoresOne.push(info.valor);
                let date = new Date(informe.fecha * 1000);
                labelOne.push(`${date.getDate()}/${date.getMonth() + 1}`);
                // labelOne.push(informe.fecha);
                labelOnes = info.nombre;
                counter = counter + 1;
                break;
              case 1:
                valoresTwo.push(info.valor);
                let dateTwo = new Date(informe.fecha * 1000);
                labelTwo.push(`${dateTwo.getDate()}/${dateTwo.getMonth() + 1}`);
                labelTwos = info.nombre;
                counter = counter + 1;
                break;

              default:
                break;
            }
            flag = flag + 1;
          }
        }
      });
    });

    const dataOne = {
      labels: labelOne,
      series: [valoresOne],
    };
    const dataTwo = {
      labels: labelTwo,
      series: [valoresTwo],
    };

    setNameGraphOne(labelOnes);
    setNameGraphTwo(labelTwos);
    setDataGraficoOne(dataOne);
    setDataGraficoTwo(dataTwo);

    // console.log(valoresOne);
    // console.log(valoresTwo);
    // console.log(valoresThree);
  };
  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };
  //Editar Variable
  const handleEditSubmit = async () => {
    let arrVar = variblesDeMaquina;
    const index = arrVar.map((object) => object._id).indexOf(vartoEdit._id);
    // vartoEdit.minimo =
    arrVar[index] = vartoEdit;
    await axios
      // .put(
      //   `http://localhost:5005/api/rondas/editavariables/${equipo._id}`,
      //   arrVar
      // )
      .put(
        `https://epstool.com/api/rondas/editavariables/${equipo._id}`,
        arrVar
      )
      .then((res) => {
        setTxtNotificacion("Variable editada con éxito");
        console.log(res);
        showNotification();
        setVartoEditModal(false);
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };
  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const notaToSave = {
      nota: textNote,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      // .post(`http://localhost:5005/api/rondas/nota/${equipo._id}`, notaToSave)
      .post(`https://epstool.com/api/rondas/nota/${equipo._id}`, notaToSave)
      .then((res) => {
        setNotas(res.data.maquina.notas);
        setTxtNotificacion("Nota guardada con éxito");
        setTextNote("");
        setNotaModal(false);
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  const handleFileSubmit = async (event) => {
    event.preventDefault();
    setUrlFileError();
    setErrorDescription();
    let flag = 0;
    !nameDescription
      ? setErrorDescription("Debe agregar un nombre al archivo")
      : (flag = flag + 1);
    !urlFile
      ? setUrlFileError("Debe adjuntar un documento o imagen")
      : (flag = flag + 1);

    if (flag === 2) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const fileToSave = {
        url: urlFile.url,
        fileName: urlFile.filename,
        fileDescription: nameDescription,
        type: urlFile.mimetype,
        userName: decoded.name,
        userCompany: decoded.company,
      };
      await axios
        // .post(`http://localhost:5005/api/rondas/file/${equipo._id}`, fileToSave)
        .post(`https://epstool.com/api/rondas/file/${equipo._id}`, fileToSave)
        .then((res) => {
          setFiles(res.data.maquina.files);
          setTxtNotificacion("Archivo guardado con éxito");
          setNameDescription("");
          setUrlFile("");
          setFilesModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };
  return (
    <div>
      {/* INFORMACION TECNICA */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <CardIcon color="success">
                    <Place />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {equipo ? `Equipo ${equipo.codMaquina}` : ""}
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setVariablesModal(true);
                    }}
                  >
                    Editar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      props.history.push(`../ingresarinforme/${equipo.uuid}`);
                    }}
                  >
                    Informe
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>{equipo ? `Tipo de equipo: ${equipo.tipo.nombre}` : ""}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>{equipo ? `Ubicación: ${equipo.ubicacion.nombre}` : ""}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {equipo
                      ? `Código: ${equipo.codEspecial}`
                      : "No registra código"}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p>Varibles:</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {variblesDeMaquina
                  ? variblesDeMaquina.map((variable, index) => (
                      <GridItem key={index} xs={12} sm={4}>
                        <p>
                          {variable.nombre}{" "}
                          {variable.tipo === 2
                            ? `${
                                variable.minimo
                                  ? `Min: ${variable.minimo}  | `
                                  : ""
                              }${
                                variable.maximo
                                  ? `Max: ${variable.maximo} `
                                  : ""
                              }`
                            : ""}
                          {variable.tipo === 2
                            ? `UM: ${variblesDeMaquina[index].unidadMedida}`
                            : ""}
                        </p>
                      </GridItem>
                    ))
                  : ""}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* REPORTES ANTERIORES */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Ultimos reportes</h4>
            </CardHeader>
            <CardBody>
              {informes.length === 0 ? (
                "No se registran informes"
              ) : (
                <Table
                  tableHead={["#", "Nombre", "Fecha", "Actions"]}
                  tableData={informes.map((informe) => [
                    informe.codigo,
                    informe.nombre,
                    new Date(informe.fecha * 1000).toLocaleString(),
                    <Button
                      justIcon
                      color="success"
                      size="sm"
                      onClick={() => {
                        props.history.push(`../informes/${informe._id}`);
                      }}
                    >
                      <Dvr />
                    </Button>,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Graficos */}
      <GridContainer>
        {dataGraficosOne ? (
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Dvr />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {/* Coloured Line Chart <small>- Rounded</small> */}
                  {nameGraphOne}
                </h4>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => {
                    props.history.push(`../graficos/${idEquipo}`);
                  }}
                >
                  Ver todos los graficos
                </Button>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={dataGraficosOne}
                  type="Line"
                  options={colouredLineChart.options}
                  listener={colouredLineChart.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
        ) : null}
        {dataGraficosTwo ? (
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Dvr />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {/* Coloured Line Chart <small>- Rounded</small> */}
                  {nameGraphTwo}
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={dataGraficosTwo}
                  type="Line"
                  options={colouredLineChart.options}
                  listener={colouredLineChart.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
        ) : null}
      </GridContainer>

      {/* REPORTES Extra */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Ultimos reportes extra</h4>
            </CardHeader>
            <CardBody>
              {informesExtra.length === 0 ? (
                "No se registran informes adicionales"
              ) : (
                <Table
                  tableHead={["#", "Nombre", "Fecha", "Actions"]}
                  tableData={informesExtra.map((informe) => [
                    informe.codigo,
                    informe.nombre,
                    new Date(informe.fecha * 1000).toLocaleString(),
                    <Button
                      justIcon
                      color="success"
                      size="sm"
                      onClick={() => {
                        props.history.push(`../informes/${informe._id}`);
                      }}
                    >
                      <Dvr />
                    </Button>,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* NOTAS */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <CardIcon color="success">
                    <Place />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Notas</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setNotaModal(true);
                    }}
                  >
                    Crear nota
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {notas.length === 0
                ? "No se registran notas"
                : notas.map((nota) => (
                    // <Card key={nota._id}>
                    //   <CardHeader>
                    //     <h4 className={classes.cardIconTitle}>
                    //       Nota del dia {nota.fecha}
                    //     </h4>
                    //   </CardHeader>
                    //   <CardBody>{nota.comentario}</CardBody>
                    //   <CardFooter>
                    //     {nota.userName} - {nota.userCompany}
                    //   </CardFooter>
                    // </Card>
                    <div key={nota._id}>
                      <strong>
                        Nota del dia{" "}
                        {new Date(nota.fecha * 1000).toLocaleString()}:
                      </strong>
                      <p>{nota.comentario}.</p>
                      <p>
                        Realizado por {nota.userName} - {nota.userCompany}
                      </p>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* FILES */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <CardIcon color="success">
                    <Place />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Archivos</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setFilesModal(true);
                    }}
                  >
                    Agregar archivo
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {files.length === 0
                ? "No se registran archivos"
                : files.map((file) => (
                    // <Card key={nota._id}>
                    //   <CardHeader>
                    //     <h4 className={classes.cardIconTitle}>
                    //       Nota del dia {nota.fecha}
                    //     </h4>
                    //   </CardHeader>
                    //   <CardBody>{nota.comentario}</CardBody>
                    //   <CardFooter>
                    //     {nota.userName} - {nota.userCompany}
                    //   </CardFooter>
                    // </Card>
                    <div key={file._id}>
                      <strong>
                        Archivo almacendo el dia{" "}
                        {new Date(file.fecha * 1000).toLocaleString()}:
                      </strong>
                      <br />
                      <strong>
                        <a href={file.url} target="_blank">
                          Ver archivo {file.fileDescripcion}
                        </a>
                      </strong>
                      <p>
                        Realizado por {file.userName} - {file.userCompany}
                      </p>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleNotaSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL ARCHIVO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={filesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFilesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir nombre de archivo"
            id="nameDescription"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setNameDescription(event.target.value);
              },
              type: "text",
              value: nameDescription,
            }}
          />
          {errorDescription ? (
            <Danger>
              <p> {errorDescription} </p>
            </Danger>
          ) : null}
          <ReactFilestack
            apikey={API_KEY}
            onSuccess={(result) => setUrlFile(result.filesUploaded[0])}
            onError={(error) =>
              setUrlFileError(
                "Se ha producido un error, porfavor intentarlo nuevamente"
              )
            }
            actionOptions={options}
            customRender={({ onPick }) => (
              <div>
                <Button fullWidth color="info" onClick={onPick}>
                  Cargar archivo
                </Button>
                {urlFile ? (
                  <Success>
                    <p> Archivo cargado </p>
                  </Success>
                ) : null}
                {urlFileError ? (
                  <Danger>
                    <p>{urlFileError}</p>
                  </Danger>
                ) : null}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleFileSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setFilesModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL LISTAR VARIABLES */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={variablesModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setVariablesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setVariablesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Seleccionar variable</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {variblesDeMaquina
            ? variblesDeMaquina.map((variable, index) =>
                variable.tipo === 2 ? (
                  <GridContainer key={index}>
                    <GridItem xs={10}>
                      <strong>{variable.nombre}</strong>{" "}
                      {variable.minimo ? `Min: ${variable.minimo}` : ""}{" "}
                      {variable.maximo ? `Max: ${variable.maximo}` : ""}{" "}
                      {variable.tipo === 2
                        ? ` ${variblesDeMaquina[index].unidadMedida}`
                        : ""}
                    </GridItem>
                    <GridItem xs={2}>
                      <Button
                        justIcon
                        color="success"
                        size="sm"
                        onClick={() => {
                          setVartoEdit(variable);
                          setVariablesModal(false);
                          setVartoEditModal(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )
              )
            : ""}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setVariablesModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Editar VARIABLE */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={vartoEditModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setVartoEditModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setVartoEditModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{vartoEdit.nombre}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={2}>
              {" "}
              <FormLabel className={classes.labelCustomHorizontal}>
                Min:{" "}
              </FormLabel>{" "}
            </GridItem>
            <GridItem xs={10}>
              <CustomInput
                // labelText="Escribir comentario"
                id="varMin"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  onChange: (event) => {
                    // vartoEdit.minimo = event.target.value;
                    vartoEdit.minimo = Number(event.target.value);
                    setVartoEdit({ ...vartoEdit });
                  },
                  value: vartoEdit.minimo ? vartoEdit.minimo : 0,
                  // value: vartoEdit.minimo,
                }}
              />
            </GridItem>
            <GridItem xs={2}>
              {" "}
              <FormLabel className={classes.labelCustomHorizontal}>
                Max:{" "}
              </FormLabel>{" "}
            </GridItem>
            <GridItem xs={10}>
              <CustomInput
                // labelText="Escribir comentario"
                id="varMax"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    vartoEdit.maximo = Number(event.target.value);
                    setVartoEdit({ ...vartoEdit });
                  },
                  type: "number",
                  value: vartoEdit.maximo ? vartoEdit.maximo : 0,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              handleEditSubmit();
            }}
            color="success"
          >
            Guardar
          </Button>
          <Button
            onClick={() => setVartoEditModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";

import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import jwt_decode from "jwt-decode";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();

  const [dataRondas, setDataRondas] = useState([]);
  const [dataDia, setDataDia] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [rondasReiniciar, setRondaReiniciar] = useState("");

  let decoded = {};
  if (localStorage.jwtToken) {
    decoded = jwt_decode(localStorage.jwtToken);
  }

  const fetchData = async () => {
    // const resultRondas = await axios("http://localhost:5005/api/rondas/dia");
    const resultRondas = await axios("https://epstool.com/api/rondas/dia");
    const date = new Date();
    const hoy = Math.floor(
      new Date(date.getFullYear(), date.getMonth(), date.getDate()) / 1000 +
        21600
    );
    if (hoy === resultRondas.data.fechaHoy) {
      setDataRondas(
        resultRondas.data.rondas.sort((a, b) => a.estado - b.estado)
      );
      setDataDia(resultRondas.data);
    } else {
      // const resultRondasAct = await axios(
      //   "http://localhost:5005/api/rondas/actualizardia"
      // );
      const resultRondasAct = await axios(
        "https://epstool.com/api/rondas/actualizardia"
      );
      // console.log(resultRondasAct.data.rondas);

      setDataRondas(
        resultRondasAct.data.rondas.sort((a, b) => a.estado - b.estado)
      );
      setDataDia(resultRondasAct.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const iniciarRonda = async (ronda) => {
    const rondas = {
      config: ronda.configuracion,
      id: ronda._id,
      rondasIniciadas: dataDia.rondasIniciadas,
      codigo: ronda.codigo,
    };
    await axios
      // .post("http://localhost:5005/api/rondas/iniciarronda", rondas)
      .post("https://epstool.com/api/rondas/iniciarronda", rondas)
      .then((e) => window.location.reload());
  };

  const reiniciarRonda = async (id) => {
    if (id) {
      await axios
        // .put(`http://localhost:5005/api/rondas/reiniciarronda/${id}`)
        .put(`https://epstool.com/api/rondas/reiniciarronda/${id}`)
        .then(() => window.location.reload());
    }
  };

  const agregarTiempo = async (id) => {
    if (id) {
      await axios
        // .put(`http://localhost:5005/api/rondas/addtime/${id}`)
        .put(`https://epstool.com/api/rondas/addtime/${id}`)
        .then(() => window.location.reload());
    }
  };

  let listRonda;

  // dataRondas.map((rondas) => )
  if (Object.keys(dataRondas).length >= 1) {
    listRonda = (
      <Card>
        <CardHeader color="success" icon>
          <CardIcon color="success">
            <QueryBuilderIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Rondas a realizar</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHead={["#", "Name", "Estado", "Actions"]}
            tableData={dataRondas.map((rondas) => [
              rondas.codigo,
              rondas.nombre,
              rondas.estado === 0
                ? "Pendiente"
                : rondas.estado === 1
                ? "Proceso"
                : "Terminado",
              rondas.estado === 0 ? (
                <GridItem>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      iniciarRonda(rondas);
                    }}
                  >
                    Iniciar
                  </Button>
                  {decoded && decoded.email === "juanjomh09@gmail.com" ? (
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => {
                        agregarTiempo(rondas._id);
                      }}
                    >
                      Tiempo
                    </Button>
                  ) : null}
                </GridItem>
              ) : (
                <GridItem>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      props.history.push(`ronda/${rondas.codigo}`);
                    }}
                  >
                    Ver
                  </Button>
                  {rondas.estado === 1 ? (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => {
                        setAlertModal(true);
                        setRondaReiniciar(rondas._id);
                      }}
                    >
                      Reiniciar
                    </Button>
                  ) : null}

                  {decoded && decoded.email === "juanjomh09@gmail.com" ? (
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => {
                        agregarTiempo(rondas._id);
                      }}
                    >
                      Tiempo
                    </Button>
                  ) : null}
                </GridItem>
              ),
            ])}
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 2, 3]}
            customHeadCellClasses={[
              classes.center,
              classes.right,
              classes.right,
            ]}
            customHeadClassesForCells={[0, 2, 3]}
          />
        </CardBody>
      </Card>
    );
  } else {
    listRonda = "No se encontraron rondas para el dia de hoy";
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        {listRonda}
      </GridItem>
      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Advertencia</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>
            Está seguro que desea reiniciar la ronda? La información almacenada
            será eliminada y no podrá recuperarse
          </p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => reiniciarRonda(rondasReiniciar)}
            color="success"
          >
            Aceptar
          </Button>
          <Button onClick={() => setAlertModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

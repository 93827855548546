import React, { useState, useEffect, PureComponent } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
// excel
import ReactExport from "react-data-export";
// react plugin for creating charts

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText.js";
import FormLabel from "@material-ui/core/FormLabel";

import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import jwt_decode from "jwt-decode";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
//Modal
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function GraficosComparativos(props) {
  const classes = useStyles();
  const [estanteGraficos, setEstanteGraficos] = useState([]);
  const [tipoMaq, setTipoMaq] = useState("");
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [datosToGraph, setDatosToGraph] = useState([]);
  const [checked, setChecked] = useState([]);
  const [varCheck, setVarCheck] = useState([]);
  const [nameChecked, setNameChecked] = useState([]);
  const [typeChecked, setTypeChecked] = useState([]);
  const [checkTipoMaqGen, setCheckTipoMaqGen] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [tc, setTC] = useState(false);
  const [checkedEquipo, setCheckedEquipos] = useState([]);
  const [dataTipos, setDataTipos] = useState([{}]);
  const [dataMaquina, setDataMaquinas] = useState([]);

  const fetchData = async () => {
    setLoadingModal(true);
    const resultTypes = await axios(
        "https://epstool.com/api/rondas/alltypes"
      // "http://localhost:5005/api/rondas/alltypes"
    );
    setDataTipos(resultTypes.data);
    setLoadingModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleFrom = (e) => {
    setDesde(e.unix());
  };
  const handleTo = (e) => {
    setHasta(e.unix());
  };

  const handleToggle = (value) => {
    // console.log(checked);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleToggleEquipos = (value, name, type) => {
    const currentIndex = checkedEquipo.indexOf(value);
    const newChecked = [...checkedEquipo];
    const newNameChecked = [...nameChecked];
    const newTypeChecked = [...typeChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      newNameChecked.push(name);
      newTypeChecked.push(type);
    } else {
      newChecked.splice(currentIndex, 1);
      newNameChecked.splice(currentIndex, 1);
      newTypeChecked.splice(currentIndex, 1);
    }
    setCheckedEquipos(newChecked);
    setNameChecked(newNameChecked);
    setTypeChecked(newTypeChecked);
  };

  const addGrafico = async () => {
    setLoadingModal(true);
    await axios
      .get(
        `https://epstool.com/api/rondas/avanzado/${checkedEquipo[0]}/${desde}/${hasta}`,
        // `http://localhost:5005/api/rondas/avanzado/${checked[0]}/${desde}/${hasta}`,
        {
          params: {
            equipos: checkedEquipo,
          },
        }
      )
      .then((result) => {
        // console.log(result);
        let dataToGrafLocal = [];
        let maximo = 0;
        let minimo = 0;
        checked.forEach((varToGraph, i) => {
          // let dataToGraf = {Rha19 : { valores: [], fecha: []}}
          result.data.informesOrdenados.forEach((maquina) => {
            let idx = checkedEquipo.indexOf(maquina.name);
            let maquinaToGraf = {
              nombre: `${nameChecked[idx]}-${varToGraph}`,
              valores: [],
              fecha: [],
            };
            maquina.informes.forEach((reporte) => {
              reporte.informacion.forEach((informe) => {
                if (informe.nombre === varToGraph) {
                  let date = new Date(reporte.fecha * 1000);
                  if (informe.valor > maximo) {
                    maximo = parseInt(informe.valor);
                  }
                  if (informe.valor < minimo) {
                    minimo = parseInt(informe.valor);
                  }
                  maquinaToGraf.valores.push({
                    x: date,
                    y: parseInt(informe.valor),
                  });
                }
              });
            });
            dataToGrafLocal.push(maquinaToGraf);
          });
        });
        setDatosToGraph(dataToGrafLocal);

        showGraphic(dataToGrafLocal, "a", "Datos", maximo, minimo);
      });
  };

  const showGraphic = async (data, i, varName, mx, mn) => {
    let dataToSeries = [];
    data.forEach((varToSerie) => {
      dataToSeries.push({ name: varToSerie.nombre, data: varToSerie.valores });
    });
    const newGraphic = [...estanteGraficos];
    let flagOneType = 1;
    for (let index = 0; index < typeChecked.length; index++) {
      const element = typeChecked[index];
      if (typeChecked[0] !== element) {
        flagOneType = 0;
        break;
      }
    }
    if (checked.length === 1 && flagOneType === 1) {
      let idxTipo = dataTipos.findIndex((x) => x._id === checkTipoMaqGen[0]);

      let idxVar = dataTipos[idxTipo].variables.findIndex(
        (x) => x.nombre === checked[0]
      );

      // let max = dataTipos[idxTipo].variables[idxVar].maximo;
      // let min = dataTipos[idxTipo].variables[idxVar].minimo;

      let maxMinAnn;

      dataTipos[idxTipo].variables[idxVar].maximo
        ? dataTipos[idxTipo].variables[idxVar].minimo
          ? (maxMinAnn = {
              yaxis: [
                {
                  y: dataTipos[idxTipo].variables[idxVar].maximo,
                  borderColor: "#FF0000",
                  label: {
                    borderColor: "#FF0000",
                    style: {
                      color: "#fff",
                      background: "#FF0000",
                    },
                    text: "Máximo",
                  },
                },
                {
                  y: dataTipos[idxTipo].variables[idxVar].minimo,
                  borderColor: "#FF0000",
                  label: {
                    borderColor: "#FF0000",
                    style: {
                      color: "#fff",
                      background: "#FF0000",
                    },
                    text: "Mínimo",
                  },
                },
              ],
            })
          : (maxMinAnn = {
              yaxis: [
                {
                  y: dataTipos[idxTipo].variables[idxVar].maximo,
                  borderColor: "#FF0000",
                  label: {
                    borderColor: "#FF0000",
                    style: {
                      color: "#fff",
                      background: "#FF0000",
                    },
                    text: "Máximo",
                  },
                },
              ],
            })
        : dataTipos[idxTipo].variables[idxVar].minimo
        ? (maxMinAnn = {
            yaxis: [
              {
                y: dataTipos[idxTipo].variables[idxVar].minimo,
                borderColor: "#FF0000",
                label: {
                  borderColor: "#FF0000",
                  style: {
                    color: "#fff",
                    background: "#FF0000",
                  },
                  text: "Mínimo",
                },
              },
            ],
          })
        : (maxMinAnn = {});

      newGraphic.push({
        _id: i,
        series: dataToSeries,
        options: {
          chart: {
            height: 350,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
              offsetX: -100,
              offsetY: 0,
            },
          },
          colors: [
            "#0101DF",
            "#FF8000",
            "#04B431",
            "#FF0000",
            "#5E610B",
            "#380B61",
          ],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: checked[0],
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            type: "datetime",
            // categories: data[0].fecha,
            title: {
              text: "Dia/Mes",
            },
          },
          yaxis: {
            title: {
              text: checked[0],
            },
            min: mn - 15,
            max: mx + 15,
          },
          legend: {
            show: true,
            // position: "top",
            // horizontalAlign: "right",
            // floating: true,
            // offsetY: -25,
            // offsetX: -5,
          },
          annotations: maxMinAnn,
        },
      });
    } else {
      newGraphic.push({
        _id: i,
        series: dataToSeries,
        options: {
          chart: {
            height: 350,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
              offsetX: -100,
              offsetY: 0,
            },
          },
          colors: [
            "#0101DF",
            "#FF8000",
            "#04B431",
            "#FF0000",
            "#5E610B",
            "#380B61",
          ],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: varName,
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            type: "datetime",

            // categories: data[0].fecha,
            title: {
              text: "Dia/Mes",
            },
          },
          yaxis: {
            title: {
              text: varName,
            },
            min: mn - 15,
            max: mx + 15,
          },
          legend: {
            show: true,
            // position: "top",
            // horizontalAlign: "right",
            // floating: true,
            // offsetY: -25,
            // offsetX: -5,
          },
          // annotations: {
          //   yaxis: [
          //     {
          //       y: 30,
          //       borderColor: "#00E396",
          //       label: {
          //         borderColor: "#00E396",
          //         style: {
          //           color: "#fff",
          //           background: "#00E396",
          //         },
          //         text: "Max",
          //       },
          //     },
          //     {
          //       y: 10,
          //       borderColor: "#00E396",
          //       label: {
          //         borderColor: "#00E396",
          //         style: {
          //           color: "#fff",
          //           background: "#00E396",
          //         },
          //         text: "Min",
          //       },
          //     },
          //   ],
          // },
        },
      });
    }

    setEstanteGraficos(newGraphic);
    setLoadingModal(false);
  };

  const handleMaquinas = async (e) => {
    // setChecked([]);
    let checkTipoGenLocal = [...checkTipoMaqGen];

    let boolTipo = checkTipoGenLocal.includes(e);

    if (boolTipo === false) {
      setLoadingModal(true);
      // console.log(checked);
      // console.log(e);
      setTipoMaq(e);
      const resultadoTipoMaq = dataTipos.find((tipo) => tipo._id === e);
      let varNum = [...varCheck];
      resultadoTipoMaq.variables.forEach((vari) => {
        if (vari.tipo === 2) {
          varNum.push({ nombre: vari.nombre, tipo: resultadoTipoMaq._id });
        }
      });

      // console.log(varNum);
      setVarCheck(varNum);
      let checkDataMaquinaLocal = [...dataMaquina];

      const resultEquipos = await axios(
        `https://epstool.com/api/rondas/allmachines/${e}`
        // `http://localhost:5005/api/rondas/allmachines/${e}`
      );
      checkDataMaquinaLocal = checkDataMaquinaLocal.concat(resultEquipos.data);
      // console.log(resultEquipos.data);
      // console.log(checkDataMaquinaLocal);
      setDataMaquinas(checkDataMaquinaLocal);
      checkTipoGenLocal.push(e);
      setCheckTipoMaqGen(checkTipoGenLocal);
      setLoadingModal(false);
    } else {
      setTipoMaq(e);
    }
  };

  let mostradorGraficos = estanteGraficos.map((grafico) => (
    <Card key={grafico._id}>
      <Chart
        options={grafico.options}
        series={grafico.series}
        type="line"
        height={500}
      />
    </Card>
  ));

  let checkListVar;

  varCheck[0]
    ? (checkListVar = varCheck.map((varChecktoShow, i) =>
        varChecktoShow.tipo === tipoMaq ? (
          checked.includes(varChecktoShow.nombre) ? (
            <FormControlLabel
              key={i}
              xs={12}
              control={
                <Checkbox
                  checked={true}
                  tabIndex={-1}
                  onClick={() => handleToggle(`${varChecktoShow.nombre}`)}
                  // checkedIcon={<Check className={classes.uncheckedIcon} />}
                  // icon={<Check className={classes.checkedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={`${varChecktoShow.nombre}`}
            />
          ) : (
            <FormControlLabel
              key={i}
              xs={12}
              control={
                <Checkbox
                  checked={false}
                  tabIndex={-1}
                  onClick={() => handleToggle(`${varChecktoShow.nombre}`)}
                  // checkedIcon={<Check className={classes.checkedIcon} />}
                  // icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={`${varChecktoShow.nombre}`}
            />
          )
        ) : (
          ""
        )
      ))
    : (checkListVar = "Seleccione tipo de maquina primero");

  let checkListEquip;

  dataMaquina[0]
    ? (checkListEquip = dataMaquina.map((varChecktoShow, i) =>
        varChecktoShow.tipo === tipoMaq ? (
          nameChecked.includes(varChecktoShow.codMaquina) ? (
            <FormControlLabel
              key={i}
              xs={12}
              control={
                <Checkbox
                  checked={true}
                  tabIndex={-1}
                  onClick={() =>
                    handleToggleEquipos(
                      `${varChecktoShow._id}`,
                      `${varChecktoShow.codMaquina}`,
                      `${varChecktoShow.tipo}`
                    )
                  }
                  // checkedIcon={<Check className={classes.checkedIcon} />}
                  // icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={`${varChecktoShow.codMaquina}`}
            />
          ) : (
            <FormControlLabel
              key={i}
              xs={12}
              control={
                <Checkbox
                  checked={false}
                  tabIndex={-1}
                  onClick={() =>
                    handleToggleEquipos(
                      `${varChecktoShow._id}`,
                      `${varChecktoShow.codMaquina}`,
                      `${varChecktoShow.tipo}`
                    )
                  }
                  // checkedIcon={<Check className={classes.checkedIcon} />}
                  // icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={`${varChecktoShow.codMaquina}`}
            />
          )
        ) : (
          ""
        )
      ))
    : (checkListEquip = "Seleccione tipo de maquina primero");

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader color="success" text>
            <CardText color="success">
              <h4 className={classes.cardTitle}>Configuración Gráficos</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Seleccione rango de fecha
                </FormLabel>
                <GridContainer>
                  <GridItem md={1}></GridItem>
                  <GridItem xs={12} md={5}>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        onChange={handleFrom}
                        timeFormat={false}
                        inputProps={{ placeholder: "Desde" }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={5}>
                    <br />

                    <FormControl fullWidth>
                      <Datetime
                        onChange={handleTo}
                        timeFormat={false}
                        inputProps={{ placeholder: "Hasta" }}
                      />
                    </FormControl>
                  </GridItem>

                  <br />
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Elementos:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione
                  </InputLabel>
                  <Select
                    value={tipoMaq}
                    onChange={(e) => handleMaquinas(e.target.value)}
                    // onChange={(e) => setTipoMaq(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    {dataTipos.map((tipos) => (
                      <MenuItem
                        key={tipos._id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={tipos._id}
                      >
                        {tipos.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Seleccione las variables a analizar:
                </FormLabel>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>{checkListVar}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Seleccione los equipos a analizar:
                </FormLabel>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>{checkListEquip}</GridItem>
            </GridContainer>
            {desde && hasta && checked[0] && checkedEquipo[0] ? (
              <Button
                color="success"
                onClick={() => {
                  addGrafico();
                }}
              >
                Generar Gráfico
              </Button>
            ) : (
              <Button
                disabled
                color="success"
                onClick={() => {
                  addGrafico();
                }}
              >
                Generar Gráfico
              </Button>
            )}
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>{mostradorGraficos}</GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loadingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoadingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {/* <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setLoadingModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button> */}
          <h4 className={classes.modalTitle}>
            Análisis de información avanzada
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridItem xs={12}>
            Cargando y ordenando la informacion solicitada...
          </GridItem>
          <GridContainer>
            <br />
            <br />
            <br />
            <GridItem xs={3}></GridItem>
            <GridItem xs={6}>
              <CircularProgress />
            </GridItem>
            <GridItem xs={3}></GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Snackbar
        place="tc"
        color="danger"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}

import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import setAuthToken from "../../utils/setAuthToken";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };
  const [data, setData] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [flag, setFlag] = React.useState("");
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.id]: event.target.value,
    });
  };
  const verifyLogInSubmit = () => {
    let errorLocal = {};
    !data.email
      ? (errorLocal.email = "Porfavor ingresar un email válido")
      : setFlag(200);
    !data.password
      ? (errorLocal.password = "Porfavor ingresar una contraseña válida")
      : setFlag(200);
    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      handleFormSubmit();
    }
  };
  const handleFormSubmit = () => {
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    // fetch("http://localhost:5000/api/users/login", {
    fetch("https://epstool.com/api/users/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setAuthToken(localStorage.jwtToken);
          return res.json();
        }
        throw res;
      })
      .then((resJson) => {
        const token = resJson.token;
        //Set token to LS
        localStorage.setItem("jwtToken", token);
        window.location.href = "../admin/dashboard";
        // return <Redirect to='/admin' />
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          setErrors(errorMessage);
        });
      });
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="warning"
              >
                <h4 className={classes.cardTitle}>Iniciar</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  type="text"
                  value={data.email}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => handleInputChange(event),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Danger>
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </Danger>
                <CustomInput
                  labelText="Password"
                  value={data.password}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => handleInputChange(event),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                <Danger>
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </Danger>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="warning"
                  simple
                  size="lg"
                  block
                  onClick={verifyLogInSubmit}
                >
                  Ingresar
                </Button>
                {data.errorMessage && (
                  <span className="form-error">{data.errorMessage}</span>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Place from "@material-ui/icons/Place";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Danger from "components/Typography/Danger";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Snackbar from "components/Snackbar/Snackbar.js";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

export default function CambiarPass(props) {
  const classes = useStyles();
  const user = jwt_decode(localStorage.jwtToken);
  const [passNew, setPassNew] = React.useState("");
  const [passConfirmation, setPassConfirmation] = React.useState("");
  const [tc, setTC] = React.useState(false);
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [errorNew, setErrorNew] = React.useState("");
  const [errorConfirmation, setErrorConfirmation] = React.useState("");
  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const userData = {
        password: passNew,
        password2: passConfirmation,
      };
      axios
        // .post(`http://localhost:5000/api/users/edit/${user.id}`, userData)
        .post(`https://epstool.com/api/users/edit/${user.id}`, userData)
        .then((res) => {
          setPassNew("");
          setPassConfirmation("");
          setErrorNew("");
          setErrorConfirmation("");
          setTxtNotificacion("Informe guardado con éxito");
          showNotification();
        })
        .catch((e) => console.log(e));
    }
  };

  const verificar = () => {
    let errors = 0;
    setErrorConfirmation("");
    setErrorNew("");
    if (!passNew || passNew.length <= 6) {
      setErrorNew("Debe ingresar una contraseña con más de 6 caracteres");
      errors++;
    }

    if (!passConfirmation || passNew !== passConfirmation) {
      setErrorConfirmation(
        "La nueva contraseña y la confirmación deben ser iguales"
      );
      errors++;
    }

    return errors;
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Proceso para cambio de contraseña
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Nueva contraseña*:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  multiline
                  labelText="Ingrese su nueva contraseña"
                  id={"newPass"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setPassNew(e.target.value),
                    type: "password",
                    value: passNew,
                  }}
                />
                <Danger>
                  {errorNew === undefined ? null : (
                    <div className="invalid-feedback">{errorNew}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Confirmar contraseña*:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  multiline
                  labelText="Escriba nuevamente la contraseña para confirmar"
                  id={"confirmationPass"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setPassConfirmation(e.target.value),
                    type: "password",
                    value: passConfirmation,
                  }}
                />
                <Danger>
                  {errorConfirmation === undefined ? null : (
                    <div className="invalid-feedback">{errorConfirmation}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <Button fullWidth color="success" onClick={handleSave}>
                Cambiar Contraseña
              </Button>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}

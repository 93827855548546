import React, { useState, useEffect } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import jwt_decode from "jwt-decode";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Dvr from "@material-ui/icons/Dvr";
import Table from "components/Table/Table.js";
import ReactFilestack from "filestack-react";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger";

//MODAL
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "components/Snackbar/Snackbar.js";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function QrEquipo(props) {
  const classes = useStyles();
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const options = {
    maxFiles: 1,
    fromSources: ["local_file_system", "audio"],
  };
  const idEquipo = props.match.params._id;
  const [modalVar, setModalVar] = React.useState();
  const [equipo, setEquipo] = React.useState();
  const [rondas, setRondas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pendientes, setPendientes] = React.useState([]);
  const [informeModal, setInformeModal] = React.useState(false);
  const [alertaModal, setAlertaModal] = React.useState(false);
  const [pendientesModal, setPendientesModal] = React.useState(false);
  const [iniciarModal, setIniciarModal] = React.useState(false);
  const [notas, setNotas] = React.useState([]);
  const [notaModal, setNotaModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [textNote, setTextNote] = useState("");
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [tc, setTC] = React.useState(false);
  const [informes, setInformes] = React.useState([]);
  const [informesExtra, setInformesExtra] = React.useState([]);
  const [filesModal, setFilesModal] = React.useState(false);
  const [nameDescription, setNameDescription] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [urlFile, setUrlFile] = useState();
  const [urlFileError, setUrlFileError] = useState();

  // CARGA DEL EQUIPO
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondas/maquina/${idEquipo}`
      `https://epstool.com/api/rondas/maquina/${idEquipo}`
    );
    const resultRondas = await axios.get(
      // `http://localhost:5005/api/rondas/buscarRondas`
      `https://epstool.com/api/rondas/buscarRondas`
    );
    console.log(result);
    let informesTipoPrincipal = [];
    let informesTipoExtra = [];
    setEquipo(result.data);
    result.data.informes.forEach((element) => {
      if (element.tipoMaq === result.data.tipo._id) {
        informesTipoPrincipal.push(element);
      } else {
        informesTipoExtra.push(element);
      }
    });
    setInformes(informesTipoPrincipal);
    setInformesExtra(informesTipoExtra);
    setRondas(resultRondas.data.iniciadas);
    setPendientes(resultRondas.data.pendientes);
    setNotas(result.data.notas);
    setFiles(result.data.files);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const irReporte = async (id, tipo, configuracion, codigo) => {
    const consulta = {
      idRonda: id,
      idMaquina: equipo._id,
      tipoMaq: equipo.tipo._id,
      tiposExtra: equipo.tiposExtra,
      tipo: tipo,
      rondaConfig: configuracion,
      zona: equipo.ubicacion._id,
    };

    // await axios.post("/api/maquinas/rondaAbierta", consulta).then((res) => {
    await axios
      .post("https://epstool.com/api/rondas/rondaAbierta", consulta)
      // .post("http://localhost:5005/api/rondas/rondaAbierta", consulta)
      .then((res) => {
        if (res.data) {
          if (res.data.estado === true) {
            // console.log(res.data);
            props.history.push(
              `../../informe/${equipo.uuid}/${codigo}/${res.data.form}/xdldggpw4931evwy`
            );
          } else {
            setAlertaModal(true);
          }
        } else {
          setAlertaModal(true);
        }
      });
  };

  const iniciarRonda = async (ronda) => {
    setLoading(true);
    const rondas = {
      config: ronda.configuracion,
      id: ronda._id,
    };
    // console.log(rondas);
    await axios
      .post("https://epstool.com/api/rondas/iniciarronda", rondas)
      // .post("http://localhost:5005/api/rondas/iniciarronda", rondas)
      .then((e) => {
        fetchData();
        setLoading(false);
        setIniciarModal(false);
      });
  };

  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const notaToSave = {
      nota: textNote,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      // .post(`http://localhost:5005/api/rondas/nota/${equipo._id}`, notaToSave)
      .post(`https://epstool.com/api/rondas/nota/${equipo._id}`, notaToSave)
      .then((res) => {
        setNotas(res.data.maquina.notas);
        setTxtNotificacion("Nota guardada con éxito");
        setTextNote("");
        setNotaModal(false);
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  const handleFileSubmit = async (event) => {
    event.preventDefault();
    setUrlFileError();
    setErrorDescription();
    let flag = 0;
    !nameDescription
      ? setErrorDescription("Debe agregar un nombre al archivo")
      : (flag = flag + 1);
    !urlFile
      ? setUrlFileError("Debe adjuntar un documento o imagen")
      : (flag = flag + 1);

    if (flag === 2) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const fileToSave = {
        url: urlFile.url,
        fileName: urlFile.filename,
        fileDescription: nameDescription,
        type: urlFile.mimetype,
        userName: decoded.name,
        userCompany: decoded.company,
      };
      await axios
        // .post(`http://localhost:5005/api/rondas/file/${equipo._id}`, fileToSave)
        .post(`https://epstool.com/api/rondas/file/${equipo._id}`, fileToSave)
        .then((res) => {
          setFiles(res.data.maquina.files);
          setTxtNotificacion("Archivo guardado con éxito");
          setNameDescription("");
          setUrlFile("");
          setFilesModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {equipo ? `Equipo ${equipo.codMaquina}` : ""}
              </h4>

              <Button
                fullWidth
                color="success"
                onClick={() => setInformeModal(true)}
              >
                Generar reporte
              </Button>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>{equipo ? `Tipo de equipo: ${equipo.tipo.nombre}` : ""}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>{equipo ? `Ubicación: ${equipo.ubicacion.nombre}` : ""}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {equipo
                      ? `Código: ${equipo.codEspecial}`
                      : "No registra código"}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p>Varibles:</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {equipo
                  ? equipo.tipo.variables.map((variable, index) => (
                      <GridItem key={index} xs={12} sm={4}>
                        <p>
                          {variable.nombre}{" "}
                          {variable.tipo === 2
                            ? `${variable.minimo} | ${variable.maximo}`
                            : ""}
                        </p>
                      </GridItem>
                    ))
                  : ""}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* REPORTES ANTERIORES */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Reportes anteriores</h4>
            </CardHeader>
            <CardBody>
              {informes.length === 0 ? (
                "No se registran informes"
              ) : (
                <Table
                  tableHead={["#", "Nombre", "Fecha", "Actions"]}
                  tableData={informes.map((informe) => [
                    informe.codigo,
                    informe.nombre,
                    new Date(informe.fecha * 1000).toLocaleString(),
                    <Button
                      justIcon
                      color="success"
                      size="sm"
                      onClick={() => {
                        props.history.push(`../../informes/${informe._id}`);
                      }}
                    >
                      <Dvr />
                    </Button>,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* REPORTES Extra */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Ultimos reportes extra</h4>
            </CardHeader>
            <CardBody>
              {informesExtra.length === 0 ? (
                "No se registran informes adicionales"
              ) : (
                <Table
                  tableHead={["#", "Nombre", "Fecha", "Actions"]}
                  tableData={informesExtra.map((informe) => [
                    informe.codigo,
                    informe.nombre,
                    new Date(informe.fecha * 1000).toLocaleString(),
                    <Button
                      justIcon
                      color="success"
                      size="sm"
                      onClick={() => {
                        props.history.push(`../informes/${informe._id}`);
                      }}
                    >
                      <Dvr />
                    </Button>,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* NOTAS */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <CardIcon color="success">
                    <Place />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Notas</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setNotaModal(true);
                    }}
                  >
                    Crear nota
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {notas.length === 0
                ? "No se registran notas"
                : notas.map((nota) => (
                    // <Card key={nota._id}>
                    //   <CardHeader>
                    //     <h4 className={classes.cardIconTitle}>
                    //       Nota del dia {nota.fecha}
                    //     </h4>
                    //   </CardHeader>
                    //   <CardBody>{nota.comentario}</CardBody>
                    //   <CardFooter>
                    //     {nota.userName} - {nota.userCompany}
                    //   </CardFooter>
                    // </Card>
                    <div key={nota._id}>
                      <strong>
                        Nota del dia{" "}
                        {new Date(nota.fecha * 1000).toLocaleString()}:
                      </strong>
                      <p>{nota.comentario}.</p>
                      <p>
                        Realizado por {nota.userName} - {nota.userCompany}
                      </p>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* FILES */}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <CardIcon color="success">
                    <Place />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Archivos</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setFilesModal(true);
                    }}
                  >
                    Agregar archivo
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {files.length === 0
                ? "No se registran archivos"
                : files.map((file) => (
                    // <Card key={nota._id}>
                    //   <CardHeader>
                    //     <h4 className={classes.cardIconTitle}>
                    //       Nota del dia {nota.fecha}
                    //     </h4>
                    //   </CardHeader>
                    //   <CardBody>{nota.comentario}</CardBody>
                    //   <CardFooter>
                    //     {nota.userName} - {nota.userCompany}
                    //   </CardFooter>
                    // </Card>
                    <div key={file._id}>
                      <strong>
                        Archivo almacendo el dia{" "}
                        {new Date(file.fecha * 1000).toLocaleString()}:
                      </strong>
                      <br />
                      <strong>
                        <a href={file.url} target="_blank">
                          Ver archivo {file.fileDescripcion}
                        </a>
                      </strong>
                      <p>
                        Realizado por {file.userName} - {file.userCompany}
                      </p>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleNotaSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />

      {/* MODAL ARCHIVO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={filesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFilesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir nombre de archivo"
            id="nameDescription"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setNameDescription(event.target.value);
              },
              type: "text",
              value: nameDescription,
            }}
          />
          {errorDescription ? (
            <Danger>
              <p> {errorDescription} </p>
            </Danger>
          ) : null}
          <ReactFilestack
            apikey={API_KEY}
            onSuccess={(result) => setUrlFile(result.filesUploaded[0])}
            onError={(error) =>
              setUrlFileError(
                "Se ha producido un error, porfavor intentarlo nuevamente"
              )
            }
            actionOptions={options}
            customRender={({ onPick }) => (
              <div>
                <Button fullWidth color="info" onClick={onPick}>
                  Cargar archivo
                </Button>
                {urlFile ? (
                  <Success>
                    <p> Archivo cargado </p>
                  </Success>
                ) : null}
                {urlFileError ? (
                  <Danger>
                    <p>{urlFileError}</p>
                  </Danger>
                ) : null}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleFileSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setFilesModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL REALIZAR INFORME */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={informeModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setInformeModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setInformeModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            Seleccione la ronda correspondiente
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {rondas.length >= 1
            ? rondas.map((ronda) => {
                return (
                  <Button
                    key={ronda._id}
                    fullWidth
                    color="success"
                    onClick={() =>
                      irReporte(
                        ronda._id,
                        ronda.tipo,
                        ronda.configuracion,
                        ronda.codigo
                      )
                    }
                  >
                    {ronda.codigo} - {ronda.nombre}
                  </Button>
                );
              })
            : "No hay rondas abiertas"}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              setInformeModal(false);
              setPendientesModal(true);
            }}
            color="success"
            simple
          >
            Iniciar Ronda Nueva
          </Button>
          <Button onClick={() => setInformeModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL ALERTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            Este equipo no pertenece a la ronda seleccionada
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h4>Porfavor elija otra ronda o inicie una ronda pendiente</h4>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setAlertaModal(false)} color="danger" simple>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Pendientes */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={pendientesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPendientesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPendientesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            Seleccione la ronda pendiente a iniciar
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {pendientes.length >= 1
            ? pendientes.map((element) => {
                return (
                  <Button
                    key={element._id}
                    fullWidth
                    color="success"
                    onClick={() => {
                      setPendientesModal(false);
                      setModalVar(element);
                      setIniciarModal(true);
                    }}
                  >
                    {element.nombre}
                  </Button>
                );
              })
            : "No hay rondas pendientes"}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            fullWidth
            onClick={() => setPendientesModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL ALERTA ABRIR RONDA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={iniciarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIniciarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setIniciarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            ¡Alerta! Esta acción no se puede deshacer
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loading === false ? (
            <p>Esta seguro de iniciar la ronda seleccionada?</p>
          ) : (
            <p>Iniciando ronda... Espere un momento</p>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => iniciarRonda(modalVar)} color="success" simple>
            Iniciar
          </Button>
          <Button onClick={() => setIniciarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
